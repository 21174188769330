.backgroundImage {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
}

.image1 {
  background-image: url("./images/image1-3.jpg");
  background-position: center;
}

.image2 {
  background-image: url("./images/image2-3.jpg");
  background-position: center;
}

.image3 {
  background-image: url("./images/image3-1.jpg");
  background-position: center;
}

.image4 {
  background-image: url("./images/image4-5.jpg");
  background-position: center;
}

.image5 {
  background-image: url("./images/image5-3.jpg");
  background-position: center;
  overflow: hidden;
}
.title {
  height: 100vh;
}

.headline {
  text-shadow: 4px 4px 5px black;
}

@media screen and (min-width: 960px) {
  .image2 {
    background-position: right;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
